import React from 'react'

import { ModalContextProvider } from './src/shared/context/modal-context'
import { UserContextProvider } from './src/shared/context/user-context'
import { initializeGA } from './src/shared/config/ga-config'

export const wrapRootElement = ({ element }) => (
	<UserContextProvider>
		<ModalContextProvider>{element}</ModalContextProvider>
	</UserContextProvider>
)

export const onClientEntry = () => {
	const GA_MEASUREMENT_ID = process.env.GATSBY_GTM_ID
	initializeGA(GA_MEASUREMENT_ID)
}
